import { Controller } from "stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  static targets = [ "image_picker", "image_wrapper", "image", "imageSid", "url" ]

  initialize() {
    if(this.element.getAttribute("data-locale")) {
      this.locale = this.element.getAttribute("data-locale");
    }

    if(this.element.getAttribute("data-uuid")) {
      this.updateResizeImages();
    }

    if(this.element.getAttribute("data-update-uuid")) {
      this.updateUuid = this.element.getAttribute("data-update-uuid");
    }
  }

  connect() {
    if(this.updateUuid) {
      setTimeout(() => {
        scroll({
          top: (0)
        });
      }, 100);
    }
  }

  updateURL() {
    if(event.target.value.length > 0) {
      let url = event.target.value.toLowerCase().trim();
      url = url.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      url = url.replaceAll(/[^a-z0-9]/g, '-');
      url = url.replaceAll(/\-{2,}/g, '-');
      url = url.replaceAll(/^\-/g, '');
      url = url.replaceAll(/\-$/g, '');
      this.urlTarget.value = url;
    } else {
      this.urlTarget.value = '';
    }
  }

  updateResizeImages() {
    let update = document.querySelector(".container.update");
    let figures = update.querySelectorAll('figure');
    figures.forEach((figure, i) => {
      figure.classList.add('image', 'fit');
    });

  }

  selectImage() {
    this.image_pickerTarget.click();
  }

  removeImage() {
    this.imageTarget.src = '';
    this.image_wrapperTarget.classList.add('is-hidden');
    this.imageSidTarget.value = '';
  }

  uploadImage() {
    let uploadsController = this;
    var input = event.target;
    const url = input.dataset.directUploadUrl;

    var files = input.files.length;
    var count = 0;

    Array.from(input.files).forEach(file => {
      const upload = new DirectUpload(file, url);
      upload.create((error, blob) => {
        if (error) {
          console.log(`ERROR: ${error}`);
        } else {
          Rails.ajax({
            url: `/${this.locale}/update/images/${btoa(blob.signed_id)}`,
            type: "get",
            data: "",
            success: function(data) {
              uploadsController.imageTarget.src = data.preview;
              uploadsController.image_wrapperTarget.classList.remove('is-hidden');
              uploadsController.imageSidTarget.value = data.sid;
            },
            error: function(data) {}
          });
        }
      })
    })

    input.value = '';
  }

}
