import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

require("jquery");
require("breakpoints-js");
require("trix")
require("@rails/actiontext")

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import jquery from 'jquery';
import * as $ from 'jquery';
import Breakpoints from 'breakpoints-js';
import browser from '../src/javascripts/browser.min.js';
import '../src/javascripts/util.js';

import "../src/javascripts/i18n.js.erb";

const images = require.context('../images', true);
const imagePath = (name) => images(name, true);
const videos = require.context('../videos', true);
const videoPath = (name) => videos(name, true);

import "controllers"

// Breakpoints
Breakpoints({
  xlarge:   [ '1281px',  '1680px' ],
  large:    [ '981px',   '1280px' ],
  medium:   [ '737px',   '980px'  ],
  small:    [ '481px',   '736px'  ],
  xsmall:   [ null,      '480px'  ]
});

window.Rails = Rails;
window.jquery = jquery;
window.$ = jquery;
window.jQuery = jquery;
window.breakpoints = Breakpoints;
window.browser = browser;
