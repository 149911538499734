import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ "picture", "video", "pictureWrapper", "pictureDescription" ]

  initialize() {

  }

  connect() {

  }

  showImage() {
    if(event.target.dataset.image) {
      this.pictureTarget.classList.remove('is-hidden')
      this.pictureTarget.src = event.target.dataset.image;
      this.pictureDescriptionTarget.innerHTML = event.target.alt;
      this.pictureWrapperTarget.classList.remove('is-hidden');
    }
    if(event.target.dataset.video) {
      this.videoTarget.classList.remove('is-hidden')
      this.videoTarget.src = event.target.dataset.video;
      this.pictureDescriptionTarget.innerHTML = '';
      this.pictureWrapperTarget.classList.remove('is-hidden');
      setTimeout(() => {
        this.videoTarget.play();
      }, 300)
    }
  }

  hideImage() {
    this.pictureWrapperTarget.classList.add('is-hidden');
    this.pictureTarget.classList.add('is-hidden')
    this.videoTarget.classList.add('is-hidden')
    this.videoTarget.pause();
    this.videoTarget.currentTime = 0;
  }
}
